<template>
<div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">

    <div class="globaxy-add p-2" @click="add = true, addButton = false">
        <div class="text-center">

            <div v-if="addButton" :style="{animation: 'fadeIn 1.3s'}">
                <feather-icon icon="PlusIcon" class="p-10 inline-flex rounded-full globaxy-add-plus" :class="[`text-success`, {'mb-10': 'true'}]" :style="{background: `rgba(var(--vs-success),.15)`}"></feather-icon>
                <h4>Add New</h4>
            </div>

            <div v-if="add" :style="{animation: 'fadeIn 1s'}">
                <form v-on:submit.prevent="submitForm">
                    <div class="vx-row">
                        <div class="vx-col sm:w-1/2 w-full mb-2">
                            <b-form-input class="w-full" label-placeholder="Name" v-model="addServer.name" name="name" />
                        </div>
                        <div class="vx-col sm:w-1/2 w-full mb-2">
                            <b-form-input class="w-full" label-placeholder="IP" v-model="addServer.ip" name="ip" />
                        </div>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col sm:w-1/2 w-full mb-2">
                            <b-form-input class="w-full" label-placeholder="Password" v-model="addServer.password" name="password" />
                        </div>
                        <div class="vx-col sm:w-1/2 w-full mb-2">
                            <b-form-input class="w-full" @keyup.enter="createServer" label-placeholder="User" v-model="addServer.user" name="user" />
                        </div>

                    </div>

                    <div class="vx-row">
                        <div class="vx-col w-full">

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapGetters
} from "vuex";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
import {
    BFormInput,
    BRow,
    BCol,
    BFormGroup
} from 'bootstrap-vue'

export default {
    components: {
        LottieAnimation,
        BFormInput,
        BFormGroup,
        BRow,
        BCol,
    },
    data() {
        return {
            addButton: true,
            add: false,
        }
    },
    computed: {
        ...mapGetters(["addServer"]),
    },

    methods: {
        createServer() {
            this.$store.dispatch("ADD_SERVER", this.addServer);
            this.add = false;
        },
    }
}
</script>

<style lang="scss">
.loading {
    position: relative;
    top: -50px;
    left: 0px;
}

.status {
    position: relative;
    top: 30px;
    left: 0px;
}
</style>
