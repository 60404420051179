<template>
    <b-row>
        <add-server></add-server>
        <list-servers :key="index" v-for="(server, index) in adminServers" :server="server" :id="index"></list-servers>
    </b-row>
</template>

<script>
import {
    mapGetters
} from "vuex";
import ListServers from "../components/ListServers.vue";
import AddServer from "../components/AddServer.vue";

export default {
    components: {
        ListServers,
        AddServer
    },

    computed: {
        ...mapGetters(["adminServers"]),
        ...mapGetters(["addServer"]),
    },

    mounted() {
        window.Echo.channel("addServer").listen(".server-created", (server) => {
            this.$store.commit("ADD_SERVER", server);
        });
        this.$store.dispatch("GET_SERVERS");
    }
}
</script>

<style lang="scss">
.globaxy-card {
    margin-top: 50px;
    border-radius: 20px;
    border: 2px solid rgba(44, 52, 64, 0.2);
}

.globaxy-add {
    margin-top: 50px;
    border-radius: 20px;
    height: 210px;
    border: 2px solid rgba(44, 52, 64, 0.2);
}

.globaxy-add-plus {
    top: 20px;
}

.button-hover {
    display: none;
}

.display:hover+.button-hover {
    display: block;
}

.title {
    position: relative;
    margin-top: 48px;
}

h1 {
    font-weight: 600;
}

.manage-server {
    position: absolute;
    right: 10px;
}

.device-name {
    position: relative;
    top: -40px;
    font-size: 23px;
}

.submit-button {
    position: relative;
    margin-top: 5px;
}
</style>
