<template>
<b-col md="6" xl="4">
    <b-card
          border-variant="primary"
          :title="server.title"
          bg-variant="transparent"
          class="shadow-none"
          :style="{animation: 'zoomIn ' +  (id * 0.17) + 's'}"
        v-on:mouseover="mouseover" v-on:mouseleave="mouseleave">

        <router-link :to="'/administration/server/' + server.id">
            <b-button v-if="hover" variant="gradient-danger" class="btn-icon rounded-circle" :style="{animation: 'zoomIn 0.6s'}">
                <feather-icon icon="UserPlusIcon" />
            </b-button>
        </router-link>
        <lottie-animation class="apache-status" path="active.json" :loop="false" :autoPlay="true" :speed="1" :width="50" :height="50" />
        <div class="text-center">
            <span class="device-name">{{ server.name }}</span>
            <a target="_blank" rel="noopener noreferrer" :href="'http://' + server.domain">
                <h4>{{ server.domain }}</h4>
            </a></h4>
            <p class="text-grey">{{ server.ip }}</p>
        </div>
    </b-card>
</b-col>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
import {
    BButton,
    BRow, BCol, BCard, BCardText, BCardTitle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    data() {
        return {
            hover: false,
        }
    },
    components: {
        LottieAnimation,
        BButton,
        BRow,
        BCol,
        BCard,
        BCardText,
        BCardTitle,
    },
    directives: {
        Ripple,
    },
    name: "Server",
    props: ['server', 'id'],
    methods: {
        mouseover: function () {
            this.hover = true
        },
        mouseleave: function () {
            this.hover = false
        }
    }
}
</script>

<style lang="scss">
.apache-status {
    position: relative;
    right: 45%;
}
</style>